@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import './app/scss/variables';
@import '../node_modules/bootstrap/scss/bootstrap';
@import "assets/styles/roboto";
@import "assets/styles/material-icons.scss";
body {
  @extend .h-100vh;
}

#pageHeadline {
  text-align: center;
  color: $primary;
  margin-top: 30px;
  margin-bottom: 35px;
}

.flex-shrink-0 {
  margin-top: 80px;
  padding-bottom: 64px;
}

.app-logo {
  // height: 2.7rem;
  // width: 2.7rem;
  padding: 6px 8px;
  // position: absolute;
  bottom: 10px;
  border: #ffffff80 1px solid;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    bottom: 6px;
  }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
