/*
$icon-font-path: 'fonts/bootstrap/';


$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);

$hska-main-red: #db0031;
$light-coral: #f08080;

$navbar-active: #ba0000;

*/

$sizes: (
  100vh: 100vh
);

$primary: #db0031;
$dropdown-link-hover-bg: lighten($primary, 50%);
// $input-group-addon-color: white;
// $input-group-addon-bg: $primary;
